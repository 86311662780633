import { ActionService } from 'src/app/services/action.service';
import { ComparisonOperators } from 'src/app/enums/comparison-operators.enum';
import { Directive, OnInit, OnDestroy } from '@angular/core';
import { FilterConditionDto, GetTableMetadataRequest, GetTableMetadataResponse, OrderByFieldDataDto, PageableRequest, TableMetaDataDto, TableMetaStructureDto } from 'src/api/litalia/models';
import { GridPageConfig } from 'src/app/config/application.config';
import { IAppState } from 'src/app/redux/app.state';
import { isNil } from 'src/app/theme/utils/utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { ServiceLocator } from '../../services/locator.service';
import { ShopConfigService } from 'src/api/litalia/services';
import { Store, State } from '@ngrx/store';
import { TableMetadataQueryTypes } from 'src/app/enums/table-metadata-query-type.enum';
import * as appStateSelectors from '../../redux/app.state';
import * as contextActions from '../../redux/actions/context.actions';

@Directive()
export class BasePage implements OnInit, OnDestroy {

    protected actionService: ActionService;
    protected appState: Store<IAppState>;
    protected currentPage = 1;
    protected isLoading = false;
    protected pageRowCount: number = GridPageConfig.DEFAULT_ROW_COUNT;
    protected state: State<IAppState>;
    protected statetValue: appStateSelectors.IAppState;
    protected shopConfigService: ShopConfigService;
    protected subscriptions: Array<Subscription> = new Array<Subscription>();

    protected defaultSortOrders: Array<OrderByFieldDataDto>;
    protected filters: { [s: string]: any; };
    protected componentFilters: { [s: string]: any; } = {};
    protected customSortOrders: Array<OrderByFieldDataDto>;
    protected defaultFilters: { [s: string]: any; } = {};
    protected tableMetadata: Array<TableMetaDataDto>;
    protected tableName: string;
    protected tableMetaStructures: Array<TableMetaStructureDto>;
    protected defaultQueryType: TableMetadataQueryTypes = TableMetadataQueryTypes.MinMax;

    private tableMetadataEmitter = new Subject<Array<TableMetaDataDto>>;
    protected get tableMetadata$(): Observable<Array<TableMetaDataDto>> {
        return this.tableMetadataEmitter ? this.tableMetadataEmitter.asObservable() : null;
    }

    protected get action$() {
        return this.actionService.action$;
    }

    constructor() {
        this.actionService = ServiceLocator.injector.get(ActionService);
        this.appState = ServiceLocator.injector.get(Store<IAppState>);
        this.state = ServiceLocator.injector.get(State<IAppState>);
        this.shopConfigService = ServiceLocator.injector.get(ShopConfigService);
        this.statetValue = this.state.getValue();
    }

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {

        if (this.subscriptions) {
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    private init() {

        if (!this.statetValue.initializedState) {
            this.initDependencies();
        }
    }

    private initDependencies() {
        // Config dependencies here
        this.appState.dispatch(new contextActions.SetInitializedAction(true));
    }

    protected getPageableRequest(maxRowCount: boolean = true): PageableRequest {

        return {
            page: this.currentPage,
            pageSize: maxRowCount ? GridPageConfig.DEFAULT_MAX_ROW_COUNT : this.pageRowCount,
            fieldConditions: this.getFilterConditions(),
            orderBy: this.getOrderByFieldData()
        } as PageableRequest;
    }

    protected setTableMetaStructures() {
        this.tableMetaStructures = this.tableName ? [] : [new Object({ tableName: this.tableName, queryType: this.defaultQueryType })];
    }

    protected getFilterConditions(): Array<FilterConditionDto> {

        let result = new Array<FilterConditionDto>();

        this.filters = {};
        if (this.defaultFilters && Object.getOwnPropertyNames(this.defaultFilters).length > 0) {

            if (this.componentFilters && Object.getOwnPropertyNames(this.componentFilters).length > 0) {
                Object.assign(this.filters, this.componentFilters, this.defaultFilters);
            } else {
                this.filters = Object.assign(this.filters, this.defaultFilters);
            }
        } else if (this.componentFilters && Object.getOwnPropertyNames(this.componentFilters).length > 0) {
            Object.assign(this.filters, this.componentFilters);
        }

        if (this.filters) {
            const ownPropertyNames = Object.getOwnPropertyNames(this.filters);
            if (ownPropertyNames) {
                result = ownPropertyNames.map(p => new Object({

                    fieldName: p,
                    value: +this.filters[p].matchMode !== ComparisonOperators.Contains ? '' + this.filters[p].value : null,
                    values: +this.filters[p].matchMode === ComparisonOperators.Contains ? this.filters[p].value instanceof Array ? this.filters[p].value.map((v: any) => v.code) : null : null,
                    comparisonOperator: +this.filters[p].matchMode
                }));
            }
        }
        return result;
    }

    protected getOrderByFieldData(): Array<OrderByFieldDataDto> {

        let result: Array<OrderByFieldDataDto>;

        if (this.customSortOrders) {

            result = this.customSortOrders;

        } else if (this.defaultSortOrders) {

            result = this.defaultSortOrders;

        } else if (this.tableMetadata && this.tableMetadata.length) {

            let defaultOrders: Array<OrderByFieldDataDto> = null;

            const tableMetadata = this.tableMetadata.find(tm => tm.tableName === this.tableName);
            if (tableMetadata) {
                defaultOrders = tableMetadata.defaultOrder;
            } else {
                defaultOrders = this.tableMetadata[0].defaultOrder;
            }

            if (defaultOrders && defaultOrders.length > 0) {

                const firstFieldName = defaultOrders[0].fieldName;
                if (firstFieldName) {
                    const firstFieldOrder = defaultOrders[0].isAscendingOrder;
                    result = [new Object({ fieldName: firstFieldName, isAscendingOrder: firstFieldOrder })];
                }
            }
        }

        return result;
    }

    protected getTableMetaStructures(reesult: (tableMetadata: Array<TableMetaDataDto>) => void = null) {

        this.setTableMetaStructures();

        if (this.tableMetaStructures) {

            const request = { tableMetaStructures: this.tableMetaStructures } as GetTableMetadataRequest;
            this.shopConfigService.postApiShopConfigGetTableMetadata(request).subscribe({
                next: (response: GetTableMetadataResponse) => {
                    this.tableMetadata = response?.tableMetadata;
                    this.tableMetadataEmitter.next(response?.tableMetadata);
                    if (reesult) {
                        reesult(response?.tableMetadata);
                    }
                },
                error: (e) => {
                    console.error(e);
                    this.isLoading = false;
                    //this.toastError('Get table metadata failed.');
                },
                complete: () => this.isLoading = false
            });
        }
    }

    protected getRangeValues(tableName: string, fieldName: string): Array<number> {

        let rangeValues: Array<number>;

        if (tableName && fieldName && this.tableMetadata) {

            const tableMetadatas = this.tableMetadata.filter(tmd => tmd.tableName === tableName);
            if (tableMetadatas) {

                const fieldMeataData = tableMetadatas.map(tmd => tmd.fieldDescriptors).map(fd => fd.find(f => f.fieldName === fieldName)).find(f => !isNil(f));
                if (fieldMeataData) {
                    rangeValues = [fieldMeataData.fieldMinValue, fieldMeataData.fieldMaxValue];
                }
            }
        }

        return rangeValues;
    }

    protected publishAction(actionId: string, arg: any = null) {
        this.actionService.publishAction(actionId, arg);
    }
}
