import { ShopProductBrandDto } from "src/api/litalia/models";

export class Brand {
  public id: number;
  public order: number;
  public name: string;
  public code: string;
  public imageUrl: string;
  public selected = false;

  constructor(dto: ShopProductBrandDto) {
    if (dto) {
      this.id = dto.id;
      this.code = dto.code;
      this.order = dto.order;
      this.name = dto.name;
      this.imageUrl = dto.imageUrl;
    }
   }  
}

export class Category {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public hasSubCategory: boolean,
    public parentId: number) { }
}

export class CategoryItem extends Category {
  public isChecked = false;
  constructor(public category: Category) {
    super(category?.id, category?.name, category?.code, category.hasSubCategory, category.parentId);
  }
}

export class Product {
  constructor(
    public id: number,
    public productId: string,
    public productBrandCode: string,
    public name: string,
    public businessName: string,
    public nameCode: string,
    public image: string,
    public productImages: Array<ProductImage>,
    public oldPrice: number,
    public newPrice: number,
    public discount: number,
    public ratingsCount: number,
    public ratingsValue: number,
    public description: string,
    public availibilityCount: number,
    public cartCount: number,
    public color: Array<string>,
    public size: Array<string>,
    public weight: number,
    public categoryId: number,
    public currencyId: number) { }
}

export class ProductImage {
    public id: number;
    public imageUrl: string;
    public isDefault: boolean;
    public order: number;
    public productPublicId: string;
    public thumbnailImageUrl: string;
}
