<mat-card class="property-item p-0" [ngClass]="[viewType+'-item', 'column-'+column]" [class.full-width-page]="fullWidthPage" fxLayout="row wrap">

    <div fxFlex="100" fxShrink="3" [fxFlex.gt-sm]="(viewType=='list') ? 40 : 100" [fxFlex.sm]="(viewType=='list') ? 50 : 100" class="thumbnail-section product-item">

        <div class="image-container-wrapper">
            <div class="image-container selectable" [routerLink]="['/products', product.productId, product.nameCode]" (click)="onSelectProduct(product)">
                <img *ngIf="product?.image" class="img" [lazyLoad]="product | ProductImageResolver" style="object-fit: none;">
            </div> 
        </div>
        
        <a [routerLink]="['/products', product.productId, product.nameCode]" class="title text-truncate" (click)="onSelectProduct(product)">            
            <div class="prodcut-name-container" *ngIf="product?.businessName; else noBusinessName">
                <div class="center uppercase font-normal">{{product?.businessName}}</div>
            </div>
            <ng-template #noBusinessName>
                <div class="center uppercase font-normal">{{product?.name}}</div>
            </ng-template>            
        </a>
        
        <h3 class="old-price text-muted"><span *ngIf="product?.oldPrice">${{product?.oldPrice | number : '1.2-2'}}</span></h3>
        <h2 class="new-price fw-300">{{product?.newPrice | currencySign: product?.currencyId}}</h2>
        
        <div class="icons">
            <app-controls [product]="product"></app-controls>
        </div>

    </div>

</mat-card>