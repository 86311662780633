/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for litalia-api services
 */
@Injectable({
  providedIn: 'root',
})
export class LitaliaApiConfiguration {
  rootUrl: string = '';
}

export interface LitaliaApiConfigurationInterface {
  rootUrl?: string;
}
