import { Component, Input, SimpleChange, OnChanges } from '@angular/core';
import { IAppState } from 'src/app/redux/app.state';
import { Product } from 'src/app/app.models';
import { Store } from '@ngrx/store';
import * as contextActions from '../../redux/actions/context.actions';

@Component({
    selector: 'app-front-product-card',
    templateUrl: './front-product-card.component.html',
    styleUrls: ['./front-product-card.component.scss']
})
export class FrontProductCardComponent implements OnChanges, OnChanges {

    @Input() viewType = 'grid';

    @Input() fullWidthPage = true;

    @Input() viewColChanged = false;

    @Input() product: Product;

    public column = 4;

    constructor(private appState: Store<IAppState>) {
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

        if (changes.viewColChanged) {
            this.getColumnCount(changes.viewColChanged.currentValue);
        }
    }

    public getColumnCount(value: number) {
        if (value === 25) {
            this.column = 4;

        } else if (value === 33.3) {

            this.column = 3;

        } else if (value === 50) {
            this.column = 2;

        } else {
            this.column = 1;
        }
    }

    public onSelectProduct(event: Product) {
        this.appState.dispatch(new contextActions.SetSelectedProductAction(event));
    }
}
