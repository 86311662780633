<footer class="bg-primary footer">

    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="subscribe-block-container">
        
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="space-between center" class="subscribe-block theme-container">
            <div fxLayout="column" fxFlex.gt-sm="50" fxLayoutAlign.gt-sm="center start" class="subscribe-title">
                <h1 class="fw-500">{{'SUBSCRIBE_NEWSLETTER' | translate}}</h1>
                <h3 class="fw-300 secondary-color">{{'STAY_UP_TO_DATE_WITH_LATEST_PRODUCTS' | translate}}</h3>
            </div>
            <form method="get" class="subscribe-search-form" fxFlex.gt-sm="50">
                <input type="text" placeholder="E-mail cím..." fxFlex>

                <div class="subscribe-button-container">
                    <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="subscribe-button mat-elevation-z0 text-muted">Feliratkozás</button>
                </div>
            </form>
            <!-- <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center start"> 
                <h1 class="fw-500">{{'SUBSCRIBE_NEWSLETTER' | translate}}</h1>
                <h3 class="fw-300 secondary-color">{{'STAY_UP_TO_DATE_WITH_LATEST_PRODUCTS' | translate}}</h3>
            </div>
            <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
                <input type="text" placeholder="Your email address..." fxFlex>
                <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">Subscribe</button>
            </form> -->
        </div>
    </div>

    <div class="link-block">
        <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 theme-container">

            <div fxFlex="100" fxFlex.gt-md="16" fxFlex.gt-sm="33.3" 
                 class="contact-information">
                <h3 class="col-title">vásárlás</h3>
                <p class="mt-1"><a routerLink="/conditions/transport" class="link secondary-color">Kézbesítés és szállítás</a></p>
                <p class="mt-1"><a routerLink="/conditions/how-to-buy" class="link secondary-color">Vásárlás módja</a></p>
                <p class="mt-1"><a routerLink="/conditions/product-change" class="link secondary-color">Termékcsere és visszaküldés</a></p>
                <p class="mt-1"><a routerLink="/conditions/complaint" class="link secondary-color">Reklamáció</a></p>
                <p class="mt-1"><a routerLink="/conditions/general-conditions" class="link secondary-color">Általános szerződési feltételek</a></p>
                <p class="mt-1"><a routerLink="/conditions/personal-data-protection" class="link secondary-color">A személyes adatok védelme</a></p>
            </div>

            <div fxFlex="100" fxFlex.gt-md="14" fxFlex.gt-sm="33.3"
                 ngClass.sm="mt-2" ngClass.xs="mt-2"
                 fxShow="false" fxShow.gt-sm> 
                <h3 class="col-title">{{'USEFUL_LINKS' | translate}}</h3>
                <!-- <p class="mt-1"><a routerLink="/contact" class="link secondary-color">{{'NAV.CONTACT' | translate}}</a></p> -->
                <p class="mt-1"><a routerLink="/compare" class="link secondary-color">{{'COMPARE' | translate}}</a></p>
                <p class="mt-1"><a routerLink="/wishlist" class="link secondary-color">{{'WISHLIST' | translate}}</a></p>
                <p class="mt-1"><a [routerLink]="appService?.Data?.totalCartCount ? '/checkout' : '/cart'" class="link secondary-color">{{'NAV.CHECKOUT' | translate}}</a></p>
            </div>

            <div fxFlex="100" fxFlex.gt-md="24" fxFlex.gt-sm="33.3" 
                 ngClass.sm="mt-2" ngClass.xs="mt-2" 
                 ngClass.gt-xs="pr-2"  class="contact-information"> 
                <h3 class="col-title">{{'CONTACT_INFORMATION' | translate}}</h3>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <mat-icon>location_on</mat-icon>
                    <span>{{office?.officeCity}}, {{office?.officeAddress}}</span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <mat-icon>call</mat-icon>
                    <span><a [href]="office?.officePhoneRef" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officePhone}}</a></span>

                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <mat-icon>mail_outline</mat-icon>
                    <span><a [href]="office?.officeMailRef" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officeMail}}</a></span>
                </p>

                <div fxLayout="row" class="mt-1 secondary-color">
                    <table>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>

                        <tr>
                            <td style="display: flex;"><mat-icon>schedule</mat-icon></td>
                            <td><span>Hétfő - Péntek</span></td>
                            <td style="text-align: end;"><span class="ml-1">9:00 - 19:00</span></td>
                        </tr>

                        <tr>
                            <td><mat-icon></mat-icon></td>
                            <td><span>Szombat</span></td>
                            <td style="text-align: end;"><span class="ml-1">9:00 - 18:00</span></td>
                        </tr>

                        <tr>
                            <td><mat-icon></mat-icon></td>
                            <td><span>Vasárnap</span></td>
                            <td style="text-align: end;"><span class="ml-1">10:00 - 15:00</span></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div fxFlex="100" fxFlex.gt-md="23" fxFlex.gt-sm="50" 
                 ngClass.md="mt-2" ngClass.sm="mt-2" ngClass.xs="mt-2"
                 style="display: flex; align-items: center; justify-content: center;">

                 <div ngClass.gt-md="image-shop-md" ngClass="image-shop-sm">
                 </div>
                 
            </div>

            <div fxFlex="100" fxFlex.gt-md="23" fxFlex.gt-sm="50"
                              ngClass.md="mt-2" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
                </agm-map>
            </div>           

        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
            <p ngClass.xs="mt-1">Copyright © {{thisYear}} All Rights Reserved 1.0</p>
            <!-- <p>Designed & Developed by <a mat-button href="http://themeseason.com/" target="_blank">ThemeSeason</a></p> -->
        </div>
    </div>

</footer>