import { Injectable } from '@angular/core';

export class GpsCoord {
    constructor(public lat: number,
                public lng: number) { }
}

export class Office {
    constructor(public gpsCoord: GpsCoord,
                public zoom: number,
                public company: string,
                public officeCity: string,
                public officeZip: string,
                public officeAddress: string,
                public officePhoneRef: string,
                public officePhone: string,
                public officeMailRef: string,
                public officeMail: string,
                public supportMailRef: string,
                public supportMail: string,
                public tax: string) { }
}

@Injectable({
    providedIn: 'root',
})
export class OfficeService {

    public office = {
        gpsCoord: {
            lat: 47.620745871071655,
            lng: 19.166679094238752,
        },
        zoom: 12,
        company: `L' italian Bolt`,
        officeCity: 'Dunakeszi',
        officeZip: '2020',
        officeAddress: 'Fóti út 120. (Tesco épülete)',
        officePhoneRef: 'tel:+36209913227',
        officePhone: '(+36) 20 991-3227',
        officeMailRef: 'mailto:litaliadesignkft@gmail.com',
        officeMail: 'litaliadesignkft@gmail.com',
        supportMailRef: 'mailto:litaliadesignkft@gmail.com',
        supportMail: 'litaliadesignkft@gmail.com',
        tax: '26562953-2-40'
    };
}
