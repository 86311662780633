
<div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="25" [routerLink]="['/products']" [queryParams]="{filterCategories: 'kitchen-utensils'}">

        <div class="banner" fxFlex="100" fxFlex.gt-sm="100" [ngStyle]="getBgImage(0)">
            <div class="info info0" fxLayoutAlign="start start">
                <div class="m-1" fxLayout="column" fxLayoutAlign="center center">
                    <div class="banner-title-container" style="top: 30px; left: 40px">
                        <h1 class="title">{{getBanner(0).title}}</h1>
                        <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    </div>
                </div> 
            </div> 
        </div>

    </div>

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="50" [routerLink]="['/products']" [queryParams]="{filterCategories: 'dishes'}">

        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="50">
            <div class="banner info1" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="67.77" [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="bottom: 20px; right: 40px">
                            <h4 class="title">{{getBanner(1).title}}</h4>
                            <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                        </div>
                    </div> 
                </div> 
            </div>
            <div class="banner info1" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="33.33" [ngStyle]="getBgImage(2)" [routerLink]="['/products']" [queryParams]="{filterCategories: 'decoration'}">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="top: 20px; right: 30px">
                            <h2 class="title">{{getBanner(2).title}}</h2>
                            <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>

        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="50">
            <div class="banner" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="33.33" [ngStyle]="getBgImage(3)" [routerLink]="['/products']" [queryParams]="{filterCategories: 'kettle'}">

                <div class="info info1" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="top: 30px; left: 30px">
                            <h2 class="title">{{getBanner(3).title}}</h2>
                            <h4 class="subtitle">{{getBanner(3).subtitle}}</h4>
                        </div>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="67.77" [ngStyle]="getBgImage(4)" [routerLink]="['/products']" [queryParams]="{filterCategories: 'glasses'}">

                <div class="info info1" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                        <div class="banner-title-container" style="bottom: 50px; left: 30px">
                            <h4 class="title">{{getBanner(4).title}}</h4>
                            <h4 class="subtitle">{{getBanner(4).subtitle}}</h4>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>

    </div>

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="25" [routerLink]="['/products']" [queryParams]="{filterCategories: 'thermos'}">

        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="100" fxFlex.gt-sm="100" [ngStyle]="getBgImage(5)">
            <div class="info info0" fxLayout="column" fxLayoutAlign="center end">
                <div class="m-1" fxLayout="column" fxLayoutAlign="center center">
                    <div class="banner-title-container" style="position: absolute; bottom: 35px; right: 33px">
                        <h1 class="title">{{getBanner(5).title}}</h1>
                        <h3 class="subtitle">{{getBanner(5).subtitle}}</h3>
                    </div>
                </div>
            </div> 
        </div>
    </div>

</div>

<!-- <div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">

        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                </div> 
            </div> 
        </div>

        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div> 
                </div> 
            </div>
        </div>

    </div>

    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">

        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(3)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(3).title}}</h2>
                      <h4 class="subtitle">{{getBanner(3).subtitle}}</h4>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div>                    
                </div>
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(4)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(4).title}}</h2>
                      <h4 class="subtitle">{{getBanner(4).subtitle}}</h4>
                      <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div>
                </div> 
            </div>
        </div>

        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(5)">
            <div class="info" fxLayout="column" fxLayoutAlign="center end">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                  <h1 class="title">{{getBanner(5).title}}</h1>
                  <h3 class="subtitle">{{getBanner(5).subtitle}}</h3>
                  <a [routerLink]="['/products']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                </div>
            </div> 
        </div>
    </div>

</div> -->