export class REDUX_CONTEXT_ACTION {

    public static readonly SET_CURRENCYID = 'SetCurrencyId';
    public static readonly SET_GENERIC_SEARCH_QUERY_CATEGORY = 'SetGenericSearchQueryCtegory';
    public static readonly SET_GENERIC_SEARCH_QUERY_TEXT = 'SetGenericSearchQueryText';
    public static readonly SET_PRODUCTBESTSELLERS = 'SetProductBestsellers';
    public static readonly SET_BRANDS = 'SetBrands';
    public static readonly SET_CATEGORIES = 'SetCategories';
    public static readonly SET_PRODUCTNEWS = 'SetProductNews';
    public static readonly SET_SELECTEDPRODUCT = 'SetSelectedProduct';
    
    public static readonly SET_APPLICATION_INITIALIZED = 'SetApplicationInitialized';
}
