/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LitaliaApiConfiguration as __Configuration } from '../litalia-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProductImageOperationDto } from '../models/product-image-operation-dto';
import { ProductImageDto } from '../models/product-image-dto';
@Injectable({
  providedIn: 'root',
})
class ProductImageService extends __BaseService {
  static readonly deleteApiProductImageDeletePath = '/api/ProductImage/Delete';
  static readonly patchApiProductImageOrderPath = '/api/ProductImage/Order';
  static readonly patchApiProductImageUpdatePath = '/api/ProductImage/Update';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  deleteApiProductImageDeleteResponse(body?: ProductImageOperationDto): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ProductImage/Delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  deleteApiProductImageDelete(body?: ProductImageOperationDto): __Observable<string> {
    return this.deleteApiProductImageDeleteResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param body undefined
   */
  patchApiProductImageOrderResponse(body?: Array<ProductImageDto>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/ProductImage/Order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  patchApiProductImageOrder(body?: Array<ProductImageDto>): __Observable<null> {
    return this.patchApiProductImageOrderResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  patchApiProductImageUpdateResponse(body?: ProductImageOperationDto): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/ProductImage/Update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  patchApiProductImageUpdate(body?: ProductImageOperationDto): __Observable<string> {
    return this.patchApiProductImageUpdateResponse(body).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ProductImageService {
}

export { ProductImageService }
