import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';

@Directive({
  selector: '[liSizeChange]',
})
export class SizeChangeDirective implements OnDestroy, OnInit {

  private changes: ResizeObserver;
  private delaySubject: Subject<ResizeObserverEntry[]> = new Subject();
  private subscriptions = new Subscription();

  @Output() public sizeChange = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any) {

    if (isPlatformBrowser(this.platformId)) {

      const element = this.elementRef.nativeElement as HTMLElement;
      if (element) {
        this.changes = new ResizeObserver((entries) => {
          this.delaySubject.next(entries);
        });
  
        this.changes.observe(element);
      }
    }
  }

  ngOnInit(): void {

    // Ez a böngésző oldalon fut
    // Az adatok átviteléhez használjuk a TransferState-t
    if (isPlatformBrowser(this.platformId)) {

      this.subscriptions.add(
        this.delaySubject.pipe(debounceTime(200)).subscribe((res) => {
          this.sizeChange.emit(res);
        })
      );
    }
  }

  ngOnDestroy(): void {

    if (isPlatformBrowser(this.platformId)) {

      if (this.changes) {
        this.changes.disconnect();
      }

      if (this.subscriptions) {
        this.subscriptions.unsubscribe();
      }
    }
  }
}
