export class ProdcutCategory {
    public static readonly ALL_CATEGORIES: number = 0;
}

export class ActionConfig {
    public static readonly SET_GENERIC_SEARCH_QUERY_CATEGORY = 'SET_GENERIC_SEARCH_QUERY_CATEGORY';
}

export class Currency {
    public static readonly EUR: number = 1;
    public static readonly USD: number = 2;
    public static readonly HUF: number = 3;
    public static readonly DEFAULT: number = Currency.HUF;
}

export class GridPageConfig {
    public static readonly DEFAULT_ROW_COUNT: number = 15;
    public static readonly DEFAULT_MAX_ROW_COUNT: number = 10000000;
}

export class GenericTableConfig {
    public static readonly PRODUCT_PAGE = 'vProduct';
}

export class PaymentTypeId {
    public static readonly BankCard: number = 1;
    public static readonly Transfer: number = 2;
    public static readonly Cash: number = 3;
    public static readonly Delivery: number = 4;
}

export class PaymentStatusTypeId {
    public static readonly New: number = 1;
    public static readonly Canceled: number = 2;
    public static readonly Processing: number = 3;
    public static readonly Completed: number = 4;
    public static readonly Rejected: number = 5;
    public static readonly Closed: number = 6;
    public static readonly Error: number = 7;
    public static readonly PendingApproval: number = 8;    
}

export class SortType {
    public static readonly Default: number = 1;
    public static readonly LowToHight: number = 2;
    public static readonly HighToLow: number = 3;
}
