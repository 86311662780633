<div fxLayout="row wrap">
    <div fxFlex="100" fxFlex.gt-md="24" fxFlex.gt-xs="50">
        <mat-card class="light-block m-0" fxLayout="row"  fxLayoutAlign="start center">
            <div class="icon icon-li_warehouse"></div>
            <div class="content">
                <p>Áruk raktáron</p>
            </div> 
        </mat-card> 
    </div>
    <div fxFlex="100" fxFlex.gt-md="24" fxFlex.gt-xs="50"> 
        <mat-card class="light-block m-0" fxLayout="row"  fxLayoutAlign="start center">
            <div class="icon icon-li_clock"></div>
            <div class="content">
                <p>24 órán belül elküldjük</p>
            </div> 
        </mat-card>
    </div>
    <div fxFlex="100" fxFlex.gt-md="24" fxFlex.gt-xs="50"> 
        <mat-card class="light-block m-0" fxLayout="row"  fxLayoutAlign="start center">
            <div class="icon icon-li_loyalty"></div>
            <div class="content">
                <p>Hűségprogram</p>
            </div> 
        </mat-card>
    </div>    
    <div fxFlex="100" fxFlex.gt-md="28" fxFlex.gt-xs="50"> 
        <mat-card class="light-block m-0" fxLayout="row"  fxLayoutAlign="start center">
            <div class="icon icon-li_transport"></div>
            <div class="content">
                <p> Ingyenes szállítás 15 000 HUF-tól</p>
            </div> 
        </mat-card>
    </div>
</div>