/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LitaliaApiConfiguration, LitaliaApiConfigurationInterface } from './litalia-api-configuration';

import { ConfigurationService } from './services/configuration.service';
import { ExternalAuthenticationService } from './services/external-authentication.service';
import { ExternalOrderService } from './services/external-order.service';
import { ExternalUtilityService } from './services/external-utility.service';
import { ProductService } from './services/product.service';
import { ProductBrandService } from './services/product-brand.service';
import { ProductImageService } from './services/product-image.service';
import { ProductOrderService } from './services/product-order.service';
import { ShopConfigService } from './services/shop-config.service';
import { ShopProductService } from './services/shop-product.service';
import { ShopProductBrandService } from './services/shop-product-brand.service';
import { SystemRoleService } from './services/system-role.service';
import { SystemRoleModuleAccessRightService } from './services/system-role-module-access-right.service';
import { SystemUserService } from './services/system-user.service';

/**
 * Provider for all litalia-api services, plus LitaliaApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    LitaliaApiConfiguration,
    ConfigurationService,
    ExternalAuthenticationService,
    ExternalOrderService,
    ExternalUtilityService,
    ProductService,
    ProductBrandService,
    ProductImageService,
    ProductOrderService,
    ShopConfigService,
    ShopProductService,
    ShopProductBrandService,
    SystemRoleService,
    SystemRoleModuleAccessRightService,
    SystemUserService
  ],
})
export class LitaliaApiModule {
  static forRoot(customParams: LitaliaApiConfigurationInterface): ModuleWithProviders<LitaliaApiModule> {
    return {
      ngModule: LitaliaApiModule,
      providers: [
        {
          provide: LitaliaApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
