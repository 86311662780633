import { BasePage } from "./base-page";
import { Directive, OnDestroy, OnInit } from "@angular/core";
import { GetProductGroupTypesResponse, GetProductsResponse, GetShopProductBrandsResponse, PageableRequest } from "src/api/litalia/models";
import { MapperService } from "src/app/services/mapper.service";
import { Category, Product } from "src/app/app.models";
import { ShopProductBrandService, ShopProductService } from "src/api/litalia/services";
import { ServiceLocator } from "src/app/services/locator.service";
import { SortItem } from "src/app/shared/interfaces/short-item.interface";
import { SortType } from "src/app/config/application.config";
import * as appStateSelectors from '../../redux/app.state';
import * as contextActions from '../../redux/actions/context.actions';
import { FormArray, FormGroup } from "@angular/forms";

@Directive()
export class ProductBasePage extends BasePage implements OnInit, OnDestroy {

    public counts = [16, 32, 48, 64];
    public count: any;
    public currencyId: number;
    public defaultCount = 1;
    public page: any;
    public products: Array<Product> = [];
    public sort: SortItem;
    public sortings = [
        { id: SortType.Default, name: 'Alapértelmezett rendezés' },
        { id: SortType.LowToHight, name: 'Olcsóbbak' },
        { id: SortType.HighToLow, name: 'Drágábbak' },
    ];
    public viewColDefault: number = 25;
    public viewCol = this.viewColDefault;
    public viewTypeDefault: string = 'grid';
    public viewType = this.viewTypeDefault;

    protected mapperService: MapperService;
    protected shopProductBrandService: ShopProductBrandService;
    protected shopProductService: ShopProductService;

    constructor() {
        super();
        this.mapperService = ServiceLocator.injector.get(MapperService);
        this.shopProductBrandService = ServiceLocator.injector.get(ShopProductBrandService);
        this.shopProductService = ServiceLocator.injector.get(ShopProductService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.count = this.counts[this.defaultCount];

        this.subscriptions.push(
            this.appState.select(appStateSelectors.currencyIdSelector).subscribe((currencyId: number) => {
                this.currencyId = currencyId;
            }),
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected getBrands() {
        console.log(`-- Base getBrands`);
        if (!this.statetValue.brandsState) {
            this.setBrands();
        }
    }

    protected setBrands() {

        this.isLoading = true;

        this.shopProductBrandService.getApiShopProductBrand().subscribe({
            next: (response: GetShopProductBrandsResponse) => {
                let brands = response?.productBrands ? response.productBrands.map(b => this.mapperService.toBrand(b)) : null;
                this.appState.dispatch(new contextActions.SetBrandsAction(brands));
            },
            error: (e) => {
                console.error(e);
                this.isLoading = false;
                //this.toastError('Get brands failed.');
            },
            complete: () => this.isLoading = false
        });
    }

    protected getCategories() {

        console.log(`-- Base getCategories`);
        if (!this.statetValue.categoriesState) {
            this.setCategories();
        }
    }

    protected setCategories() {

        this.isLoading = true;

        this.shopProductService.getApiShopProductGroupTypes().subscribe({
            next: (response: GetProductGroupTypesResponse) => {
                if (response?.productGroupTypes?.length) {
                    const productGroupTypes =  response.productGroupTypes.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1);
                    let categories = productGroupTypes.map(c => this.mapperService.toCategory(c));
                    categories = [...[{ "id": 0, "name": "Minden kategória", "hasSubCategory": false, "parentId": 0 } as Category], ...categories];
                    this.appState.dispatch(new contextActions.SetCategoriesAction(categories));
                }
            },
            error: (e) => {
                console.error(e);
                this.isLoading = false;
                //this.toastError('Get categories failed.');
            },
            complete: () => this.isLoading = false
        });
    }

    protected getProductsRequest(): PageableRequest {
        return this.getProductsRequest();
    }

    protected getProducts() {

        this.isLoading = true;

        const request = this.getProductsRequest();

        this.shopProductService.postApiShopProductList(request).subscribe({

            next: (response: GetProductsResponse) => {
                if (response?.products?.length) {
                    const productsOrdered = response?.products.sort((tn1, tn2) => (tn1.productId > tn2.productId) ? 1 : ((tn2.productId > tn1.productId) ? -1 : 0));
                    this.products = productsOrdered.map(p => this.mapperService.toProduct(p));
                } else {
                    this.products = [];
                }
            },
            error: (e) => {
                console.error(e);
                this.isLoading = false;
                //this.toastError('Get products failed.');
            },
            complete: () => this.isLoading = false
        });
    }

    public changeCount(count: number) {
        this.count = count;
        this.getProducts();
    }

    public changeSorting(sort: SortItem) {
        this.sort = sort;
    }

    public changeViewType(viewType: string, viewCol: number) {
        this.viewType = viewType;
        this.viewCol = viewCol;
    }

    protected validateControls(group: FormGroup | FormArray | undefined): void {

        Object.keys(group!.controls).forEach((key: string) => {
            const abstractControl = group!.controls[key];

            if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
                this.validateControls(abstractControl);
            } else {
                abstractControl.markAsDirty();
                abstractControl.updateValueAndValidity();
            }
        });
    }
}
