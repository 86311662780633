/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LitaliaApiConfiguration as __Configuration } from '../litalia-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetShopProductBrandsResponse } from '../models/get-shop-product-brands-response';
import { PageableRequest } from '../models/pageable-request';
@Injectable({
  providedIn: 'root',
})
class ShopProductBrandService extends __BaseService {
  static readonly postApiShopProductBrandListPath = '/api/ShopProductBrand/List';
  static readonly getApiShopProductBrandPath = '/api/ShopProductBrand';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiShopProductBrandListResponse(body?: PageableRequest): __Observable<__StrictHttpResponse<GetShopProductBrandsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ShopProductBrand/List`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductBrandsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiShopProductBrandList(body?: PageableRequest): __Observable<GetShopProductBrandsResponse> {
    return this.postApiShopProductBrandListResponse(body).pipe(
      __map(_r => _r.body as GetShopProductBrandsResponse)
    );
  }

  /**
   * @return Success
   */
  getApiShopProductBrandResponse(): __Observable<__StrictHttpResponse<GetShopProductBrandsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ShopProductBrand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductBrandsResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiShopProductBrand(): __Observable<GetShopProductBrandsResponse> {
    return this.getApiShopProductBrandResponse().pipe(
      __map(_r => _r.body as GetShopProductBrandsResponse)
    );
  }
}

module ShopProductBrandService {
}

export { ShopProductBrandService }
