import { Brand, Category, Product } from '../app.models';
import { createSelector } from '@ngrx/store';
import { ShopProductDto } from "src/api/litalia/models";

export interface IAppState {

    // context

    genericSearchQueryTextState: string;

    genericSearchQueryCategoryState: number;

    initializedState: boolean;

    productBestsellersState: Array<ShopProductDto>;

    brandsState: Array<Brand>;

    categoriesState: Array<Category>;
    
    productNewsState: Array<ShopProductDto>;

    selectedProductState: Product;

    currencyIdState: number;
}

// context
export const genericSearchQueryTextState = (state: IAppState) => state.genericSearchQueryTextState;
export const genericSearchQueryTextSelector = createSelector(
    genericSearchQueryTextState,
    (state: string) => state
);

export const genericSearchQueryCategoryState = (state: IAppState) => state.genericSearchQueryCategoryState;
export const genericSearchQueryCategorySelector = createSelector(
    genericSearchQueryCategoryState,
    (state: number) => state
);

export const initializedState = (state: IAppState) => state.initializedState;
export const initializedSelector = createSelector(
    initializedState,
    (state: boolean) => state
);

export const productBestsellersState = (state: IAppState) => state.productBestsellersState;
export const productBestsellersSelector = createSelector(
    productBestsellersState,
    (state: Array<ShopProductDto>) => state
);

export const brandsState = (state: IAppState) => state.brandsState;
export const brandsSelector = createSelector(
    brandsState,
    (state: Array<Brand>) => state
);

export const categoriesState = (state: IAppState) => state.categoriesState;
export const categoriesSelector = createSelector(
    categoriesState,
    (state: Array<Category>) => state
);

export const productNewsState = (state: IAppState) => state.productNewsState;
export const productNewsSelector = createSelector(
    productNewsState,
    (state: Array<ShopProductDto>) => state
);

export const selectedProductState = (state: IAppState) => state.selectedProductState;
export const selectedProducSelector = createSelector(
    selectedProductState,
    (state: Product) => state
);

export const currencyIdState = (state: IAppState) => state.currencyIdState;
export const currencyIdSelector = createSelector(
    currencyIdState,
    (state: number) => state
);
