/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LitaliaApiConfiguration as __Configuration } from '../litalia-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetSystemUsersResponse } from '../models/get-system-users-response';
import { GetSystemUsersRequest } from '../models/get-system-users-request';
import { SystemUserDto } from '../models/system-user-dto';
import { DeleteUserRequestDto } from '../models/delete-user-request-dto';
import { LoginPasswordDto } from '../models/login-password-dto';
import { ResponseBase } from '../models/response-base';
import { LoginPasswordSetDto } from '../models/login-password-set-dto';
import { SystemUserSettingDto } from '../models/system-user-setting-dto';
@Injectable({
  providedIn: 'root',
})
class SystemUserService extends __BaseService {
  static readonly getApiSystemUserLogoffPath = '/api/SystemUser/Logoff';
  static readonly postApiSystemUserGetPath = '/api/SystemUser/Get';
  static readonly postApiSystemUserPath = '/api/SystemUser';
  static readonly putApiSystemUserPath = '/api/SystemUser';
  static readonly deleteApiSystemUserPath = '/api/SystemUser';
  static readonly putApiSystemUserLoginPasswordResetPath = '/api/SystemUser/LoginPasswordReset';
  static readonly postApiSystemUserUserLoginPasswordSetPath = '/api/SystemUser/UserLoginPasswordSet';
  static readonly putApiSystemUserSettingPath = '/api/SystemUser/Setting';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  getApiSystemUserLogoffResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SystemUser/Logoff`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getApiSystemUserLogoff(): __Observable<null> {
    return this.getApiSystemUserLogoffResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiSystemUserGetResponse(body?: GetSystemUsersRequest): __Observable<__StrictHttpResponse<GetSystemUsersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SystemUser/Get`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetSystemUsersResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiSystemUserGet(body?: GetSystemUsersRequest): __Observable<GetSystemUsersResponse> {
    return this.postApiSystemUserGetResponse(body).pipe(
      __map(_r => _r.body as GetSystemUsersResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiSystemUserResponse(body?: SystemUserDto): __Observable<__StrictHttpResponse<SystemUserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SystemUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystemUserDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiSystemUser(body?: SystemUserDto): __Observable<SystemUserDto> {
    return this.postApiSystemUserResponse(body).pipe(
      __map(_r => _r.body as SystemUserDto)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  putApiSystemUserResponse(body?: SystemUserDto): __Observable<__StrictHttpResponse<SystemUserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/SystemUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystemUserDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  putApiSystemUser(body?: SystemUserDto): __Observable<SystemUserDto> {
    return this.putApiSystemUserResponse(body).pipe(
      __map(_r => _r.body as SystemUserDto)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  deleteApiSystemUserResponse(body?: DeleteUserRequestDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/SystemUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  deleteApiSystemUser(body?: DeleteUserRequestDto): __Observable<number> {
    return this.deleteApiSystemUserResponse(body).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param body undefined
   */
  putApiSystemUserLoginPasswordResetResponse(body?: LoginPasswordDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/SystemUser/LoginPasswordReset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  putApiSystemUserLoginPasswordReset(body?: LoginPasswordDto): __Observable<null> {
    return this.putApiSystemUserLoginPasswordResetResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiSystemUserUserLoginPasswordSetResponse(body?: LoginPasswordSetDto): __Observable<__StrictHttpResponse<ResponseBase>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SystemUser/UserLoginPasswordSet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseBase>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiSystemUserUserLoginPasswordSet(body?: LoginPasswordSetDto): __Observable<ResponseBase> {
    return this.postApiSystemUserUserLoginPasswordSetResponse(body).pipe(
      __map(_r => _r.body as ResponseBase)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  putApiSystemUserSettingResponse(body?: SystemUserSettingDto): __Observable<__StrictHttpResponse<SystemUserSettingDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/SystemUser/Setting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystemUserSettingDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  putApiSystemUserSetting(body?: SystemUserSettingDto): __Observable<SystemUserSettingDto> {
    return this.putApiSystemUserSettingResponse(body).pipe(
      __map(_r => _r.body as SystemUserSettingDto)
    );
  }
}

module SystemUserService {
}

export { SystemUserService }
