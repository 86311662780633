import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { ProfilePicturePipe } from './profilePicture.pipe';
import { UserSearchPipe } from './user-search.pipe';
import { ProductImageResolverPipe } from './product-image-resolver.pipe';
import { CurrencySignPipe } from './currency-sign.pipe';
import { BrandImageResolverPipe } from './brand-image-resolver.pipe';
import { ProductBigImageResolverPipe } from './product-big-image-resolver.pipe';
import { ProductSmallImageResolverPipe } from './product-small-image-resolver.pipe';

@NgModule({
    imports: [ 
        CommonModule 
    ],
    declarations: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        CurrencySignPipe,
        ProfilePicturePipe,
        UserSearchPipe,
        BrandImageResolverPipe,
        ProductImageResolverPipe,
        ProductBigImageResolverPipe,
        ProductSmallImageResolverPipe,
    ],
    exports: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        CurrencySignPipe,
        ProfilePicturePipe,
        UserSearchPipe,
        BrandImageResolverPipe,
        ProductImageResolverPipe,
        ProductBigImageResolverPipe,
        ProductSmallImageResolverPipe,
    ]
})
export class PipesModule { }
