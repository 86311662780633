import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Office, OfficeService } from 'src/app/services/office.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number;
  public lng: number;
  public office: Office;
  public thisYear = new Date().getFullYear();
  public zoom: number;

  constructor(
    public appService: AppService,
    public officeService: OfficeService) {
    this.office = officeService.office;
    this.lat = officeService.office.gpsCoord.lat;
    this.lng = officeService.office.gpsCoord.lng;
    this.zoom = officeService.office.zoom;
  }

  ngOnInit() { }

  subscribe(){ }

}