import { Brand } from 'src/app/app.models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IAppState } from 'src/app/redux/app.state';
import { ProductBasePage } from 'src/app/pages/base/product-base-page';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import * as appStateSelectors from '../../redux/app.state';

@Component({
    selector: 'app-brand-menu',
    templateUrl: './brand-menu.component.html',
    styleUrls: ['./brand-menu.component.scss']
})
export class BrandMenuComponent extends ProductBasePage implements OnInit, OnDestroy {

    public brands: Array<Brand>;
    public config: SwiperConfigInterface = {};

    ngOnInit() {

        const statetValue: IAppState = this.state.getValue();
        if (!statetValue.brandsState) {
            console.log(`-- BrandMenuComponent getBrands`);
            this.getBrands();
        };

        this.subscriptions.push(
            this.appState.select(appStateSelectors.brandsSelector).subscribe(brands => {
                this.brands = brands;
            }),
        );
    }

    ngAfterViewInit() {
        this.config = {
            observer: true,
            slidesPerView: 7,
            spaceBetween: 16,
            keyboard: true,
            navigation: false,
            pagination: false,
            grabCursor: true,
            loop: false,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 7000,
                disableOnInteraction: false
            },
            speed: 700,
            effect: "slide",
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 2
                },
                600: {
                    slidesPerView: 3,
                },
                960: {
                    slidesPerView: 4,
                },
                1280: {
                    slidesPerView: 5,
                },
                1500: {
                    slidesPerView: 6,
                }
            }
        }
    }
}
